import React, { ComponentType, FC } from "react";
import { useRouter } from "next/router";
import { useAuthenticationStatus, useUserData } from "@nhost/nextjs";
import { Box, Spinner } from "@chakra-ui/react";
import { adminUserEmails, isAdmin, isAllowedToVisit } from "../../config/admins";

export const withAuth = <P extends object>(Component: ComponentType<P>): FC<P> => {
  return function AuthProtected(props: P) {
    const router = useRouter();
    const { isLoading, isAuthenticated } = useAuthenticationStatus();
    const user = useUserData();

    // wait to see if the user is logged in or not.

    if (isLoading) {
      return (
        <Box
          h={`100vh`}
          w={"full"}
          justifyContent={"center"}
          alignItems={"center"}
          display={"flex"}
          bg={"gray.50"}
        >
          <Spinner size={`xl`} thickness={`4px`} color={"blue.600"} />
        </Box>
      );
    }

    if (!isAuthenticated) {
      router.push("/auth/login");
      return <div>...</div>;
    }

    if (!isAllowedToVisit(user?.email || ``, router.pathname)) {
      router.push("/");
      return <div>...</div>;
    }

    return <Component {...props} />;
  };
};
